import { IKnowledgeBaseDocument } from '../../../../types/knowladgeBaseDocument.type'
import { Button, Popconfirm, Table, Tooltip, Typography } from 'antd'
import { DeleteOutlined, LinkOutlined } from '@ant-design/icons'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { IPaginationParams } from '../../../../types/pagination-params.type'
import { TablePaginationConfig } from 'antd/es/table'
import { useTranslation } from 'react-i18next'
import { KnowledgeBaseRoutes } from '../../../../types/enums/knowledge-base-routes.enum'
import { formatDate } from '../../../../helpers/date'

type Props = {
  documents: IKnowledgeBaseDocument[]
  onDelete: (documentId: string) => void
  isLoading: boolean
  // setKnowledgeBasePage: (key: string) => void
  pagination: IPaginationParams
  count: number
  setPagination: (params: IPaginationParams) => void
}

const KnowledgeBaseDocumentsList = ({ documents, count, onDelete, isLoading, pagination, setPagination }: Props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation('knowledgeBase')

  const navigateToDocument = (documentId: string) => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('documentId', documentId)
    navigate({ pathname: '/storage/' + KnowledgeBaseRoutes.review, search: searchParams.toString() })
  }

  const columns = [
    {
      title: t('documentsTab.knowledgeBaseDocumentsList.name'),
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: IKnowledgeBaseDocument) => (
        <Typography.Link onClick={() => navigateToDocument(record.id)}>{text}</Typography.Link>
      ),
    },
    {
      title: t('documentsTab.knowledgeBaseDocumentsList.uploadedAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date: string) => formatDate(date),
    },
    {
      title: t('documentsTab.knowledgeBaseDocumentsList.modifiedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (date: string) => formatDate(date),
    },
    {
      title: t('documentsTab.knowledgeBaseDocumentsList.actions'),
      key: 'actions',
      render: (text: string, record: IKnowledgeBaseDocument) => (
        <div style={{ display: 'flex', gap: '10px' }}>
          <Popconfirm
            title={t('documentsTab.knowledgeBaseDocumentsList.confirmDelete')}
            onConfirm={() => onDelete(record.id)}
            okText={t('yes')}
            cancelText={t('no')}
            placement={'leftTop'}
          >
            <Tooltip title={t('documentsTab.knowledgeBaseDocumentsList.deleteFile')}>
              <Button icon={<DeleteOutlined />} />
            </Tooltip>
          </Popconfirm>
          <Tooltip title={t('documentsTab.knowledgeBaseDocumentsList.openFile')}>
            <Button icon={<LinkOutlined />} onClick={() => window.open(record.url, '_blank')} />
          </Tooltip>
        </div>
      ),
    },
  ]

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setPagination({
      page: pagination.current || 1,
      pageSize: pagination.pageSize || 10,
    })
  }

  return (
    <Table
      columns={columns}
      scroll={{ y: 400 }}
      dataSource={documents}
      rowKey="id"
      className="custom-scroll"
      pagination={{
        position: ['bottomCenter'],
        total: count,
        current: pagination.page,
        pageSize: pagination.pageSize,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'],
      }}
      loading={isLoading}
      onChange={handleTableChange}
    />
  )
}

export default KnowledgeBaseDocumentsList
